// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

document.addEventListener("turbolinks:load", () => {
  const fileInput = document.querySelector('#file-upload input[type=file]');
  if (fileInput) {
    fileInput.onchange = () => {
      if (fileInput.files.length > 0) {
        const fileName = document.querySelector('#file-upload .file-name');
        fileName.textContent = fileInput.files[0].name;
      }
    }
  }

  /* delete notices after 5 seconds */
  const noticeElements = Array.from(document.getElementsByClassName("dissapear"));
  noticeElements.forEach(function (element) {
    setTimeout(function() {
      let fade = setInterval(function () {
        if (!element.style.opacity) {
          element.style.opacity = 1;
        }
        if (element.style.opacity > 0) {
          element.style.opacity -= 0.05;
        } else {
          clearInterval(fade);
          element.remove();
        }
      }, 50);
    }, 10000);
  });

})
